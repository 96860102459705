<template>
  <div id="page-grey-cloth-sheet" class="container">
    <!-- 顶部筛选 -->
    <div class="common-filter-block">
      <div class="common-filter-item">
        <span style="margin-right: 10px">入库时间</span>
        <DatePicker
          style="width: 220px"
          type="daterange"
          placeholder="请选择日期范围"
          :clearable="false"
          v-model="listParam.filterDateRange"
          @on-change="handleDateRangeChange"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">订单交期</span>
        <DatePicker
          type="date"
          placeholder="请选择日期"
          v-model="listParam.deliveryDate"
          @on-change="handleDateChange"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">机台号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.machineNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">挡车工</span>
        <Select v-model="listParam.workerName" style="width: 120px" @on-change="getTableListData">
          <Option value="all" key="all">全部</Option>
          <Option v-for="(item, index) in allWorkerNameList" :value="item.name" :key="index">
            {{ item.name }}</Option
          >
        </Select>
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">坯布指示单</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.planOrderNum"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
      <div class="common-filter-item">
        <span style="margin-right: 10px">货号</span>
        <Input
          placeholder="请输入搜索内容"
          v-model.trim="listParam.greyClothItemNo"
          @keyup.enter.native="getTableListData"
          style="width: 180px"
        />
      </div>
    </div>
    <div class="common-action-block">
      <div class="common-action-item" @click="getTableListData">
        <i class="iconfont iconicon-shuaxin"></i>
        刷新
      </div>
      <div class="common-action-item" @click="exportTableList">
        <i class="iconfont iconicon-daochu"></i>
        导出
      </div>
    </div>

    <!-- 表格 -->
    <Table
      border
      show-summary
      :summary-method="handleSummary"
      :loading="loading"
      :columns="tableColumns"
      :data="tableData"
    >
      <!-- <template slot-scope="{ row }" slot="finishRate">
        <span>{{ Number(row.finishRate) * 100 + "%" }}</span>
      </template> -->
    </Table>

    <!-- 分页 -->
    <Page
      class-name="common-page"
      show-sizer
      show-elevator
      show-total
      :total="listDataTotal"
      :page-size="listParam.pageSize"
      :current="listParam.pageNum"
      @on-page-size-change="pageSizeChange"
      @on-change="pageNumberChange"
    />
  </div>
</template>

<script>
import { fetchSheetReportList } from "./api";

export default {
  name: "GreyClothSheet",
  data() {
    return {
      allWorkerNameList: [], // 形如 [{name: 'aaa'},{name:'bbb'}]，后端返过来就长这样

      // 首页表格配置
      loading: false,
      listParam: {
        machineNo: "", // 如 '301#'
        workerName: "all", // 挡车工姓名，默认 'all' 搜索全部
        deliveryDate: "", // 交货日期，如 '2021-07-16'，精确到天
        startDate: this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"), // 如 '2021-07-16'，精确到天
        endDate: this.moment(new Date()).format("YYYY-MM-DD"),
        filterDateRange: [
          this.moment(Date.now() - 86400000 * 7).format("YYYY-MM-DD"),
          this.moment(new Date()).format("YYYY-MM-DD"),
        ], // 前端用，

        planOrderNum: "", // 坯布指示单
        greyClothItemNo: "", // 货号 

        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      tableColumns: [
        {
          title: "坯布指示单",
          key: "orderNumber",
          minWidth: 180,
        },
        {
          title: "订单交期",
          key: "deliveryDate",
          minWidth: 120,
        },
        {
          title: "挡车工",
          key: "persons",
          minWidth: 360,
        },
        {
          title: "机台号",
          key: "deviceNumbers",
          minWidth: 360,
        },
        {
          title: "产量",
          key: "weight",
          minWidth: 120,
        },
        {
          title: "计划产量",
          key: "planWeight",
          minWidth: 120,
        },
        {
          title: "计划匹数",
          key: "planPieceNum",
          minWidth: 120,
        },
        {
          title: "出仓产量",
          key: "outStorageNum",
          minWidth: 120,
        },
        {
          title: "出仓匹数",
          key: "outStoragePieceNum",
          minWidth: 120,
        },
        {
          title: "未生产产量",
          key: "unproducedNum",
          minWidth: 120,
        },
        {
          title: "未生产匹数",
          key: "unproducedPieceNum",
          minWidth: 120,
        },
        {
          title: "未出仓匹数",
          key: "unOutStoragePieceNum",
          minWidth: 120,
        },
        {
          title: "未出仓数量",
          key: "unOutStorageNum",
          minWidth: 120,
        },
        {
          title: "已生产未出仓匹数",
          key: "producedNum",
          minWidth: 180,
        },
        {
          title: "已生产未出仓重量",
          key: "producedPieceNum",
          minWidth: 180,
        },
        {
          title: "匹数",
          key: "quantity",
          minWidth: 120,
        },
        {
          title: "订单需求数",
          key: "planNum",
          minWidth: 120,
        },
        {
          title: "货号",
          key: "greyClothItemNo",
          minWidth: 120,
        },
        {
          title: "完成百分比",
          key: "finishRate",
          // slot: "finishRate",
          minWidth: 120,
        },
      ],

      summaryRow: {
        // 汇总，表格最后一行
        totalWeight: 0,
        totalQuantity: 0,
      },

      listDataTotal: 0, // 分页器数据总数
    };
  },
  created() {
    this.getTableListData();
  },
  methods: {
    handleDateRangeChange(formatDate, DateObj) {
      this.listParam.startDate = formatDate[0];
      this.listParam.endDate = formatDate[1];

      this.getTableListData();
    },
    handleDateChange(formatDate, DateObj) {
      this.listParam.deliveryDate = formatDate;
      this.getTableListData();
    },

    handleSummary({ columns, data }) {
      const sums = {};

      columns.forEach((column, index) => {
        const key = column.key;
        if (index === 0) {
          sums[key] = {
            key,
            value: "汇总",
          };
        } else if (key === "weight") {
          sums[key] = {
            key,
            value: this.summaryRow.totalWeight,
          };
        } else if (key === "quantity") {
          sums[key] = {
            key,
            value: this.summaryRow.totalQuantity,
          };
        } else {
          sums[key] = {
            key,
            value: "",
          };
        }
      });

      return sums;
    },

    getTableListData() {
      const { filterDateRange, ...reqParamObj } = this.listParam;
      fetchSheetReportList(reqParamObj)
        .then((res) => {
          if (res.data.success === 1) {
            const { totalWeight, totalQuantity, name, list } = res.data.body;

            this.summaryRow = { totalWeight, totalQuantity };
            this.allWorkerNameList = name; // res的name 形如 [{name: 'aaa'},{name:'bbb'}]
            this.tableData = list.list;
            this.listDataTotal = list.total;
            this.loading = false;
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    pageSizeChange(pageSize) {
      this.listParam.pageSize = pageSize;
      this.listParam.pageNum = 1;
      this.getTableListData();
    },
    pageNumberChange(pageNum) {
      this.listParam.pageNum = pageNum;
      this.getTableListData();
    },

    exportTableList() {
      const { pageNum, pageSize, ...otherParam } = this.listParam;
      const { filterDateRange, ...data } = otherParam;
      const dataStr = this.qs.stringify(data);
      window.location.href = `${this.baseUrl}/dtsum/zongtong/report/statisticalFormsController/runningStatusSelectExprot?${dataStr}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/css/common.scss";

.container {
}
</style>
